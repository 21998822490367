<template>
  <div class="iq-style4 order-container curva-list mb-5">
    <b-row class="p-3">
      <b-col lg="2">
        <div class="numberOfItems text-center">
          <h1 class="text-warning">{{ order.number_of_items }}</h1>
          <span>{{ $t('order.items') }}</span>
        </div>
      </b-col>
      <b-col lg="8">
        <b-row>
          <b-col lg="6" class="pt-2 text-initial"><span class="px-2">{{ $t('order.no') }}</span>
            <p>{{order.id}}</p></b-col>
          <!-- <b-col lg="6" class="pt-2 text-initial"><span class="px-2">{{ $t('order.date') }}</span>
            <p>{{$route.params.date}}</p></b-col> -->
          <b-col lg="12" class="pt-2 text-initial"><span class="px-2">{{ $t('order.address') }}</span>
            {{ order.address.street }}
            <p>{{ order.address.street }} {{ order.address.city ? order.address.city.name : '' }} {{ order.address.region ? order.address.region.name : '' }}</p>
          </b-col>
        </b-row>
      </b-col>
        <span class="order-status-tag grey-tag">
          {{ order.order_state }}
        </span>
    </b-row>
    <hr>
    <b-row class="p-3">
      <b-col lg="2" class="text-warning p-4">{{ $t('cart.summary') }}</b-col>
      <b-col lg="10">
        <b-row>
          <b-col lg="6" class="d-flex justify-content-between gap-5 px-5">
            <p>{{ $t('cart.tItems') }}</p> <p>{{ order.number_of_items }}</p>
          </b-col>
          <b-col lg="6" class="d-flex justify-content-between gap-5 px-5">
            <p>{{ $t('cart.tax') }}</p><p>0 {{ $t('egp') }}</p>
          </b-col>
        </b-row>
        <b-row>
          <b-col lg="6" class="d-flex justify-content-between gap-5 px-5">
            <p>{{ $t('cart.subtotal') }}</p>
            <p>{{ order.products.map(item => item.total_price).reduce((prev, curr)=> prev + curr) }}
               {{ $t('egp') }}</p>
          </b-col>
          <b-col lg="6" class="d-flex justify-content-between gap-5 px-5">
            <p>{{ $t('cart.fees') }}</p><p>{{order.shipping || 0}} {{ $t('egp') }}</p>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <b-row class="iq-style3 d-flex justify-content-center">
      <div class="p-3">
        <h4>{{ $t('cart.total') }}
          <span class="text-warning">{{order.total_price}}</span> {{ $t('egp') }}</h4>
      </div>
    </b-row>
    <b-table responsive show-empty :fields="tableHeader"
             :items="order.products"  primary-key="id"
             class="curva-table" tbody-tr-class="curva-table-row">

      <template #cell(productName)="data">
        <!--      {{data.item.product.id}}-->
        <router-link v-if="data.item.info" :to="{name: 'productDetails', params: {id: data.item.info.product.id}}" class="d-flex align-items-center">
          <img :src="data.item.info.image" class="table-img mx-2">
          <span style="max-width: 200px; display:inline-block; text-wrap: wrap; white-space: wrap;">{{data.item.info.product.name.length > 20 ? data.item.info.product.name.substr(0,20) + '...' : data.item.info.product.name }}</span>
        </router-link>
      </template>
      <template #cell(size)="data">
        {{data.item.info.size.name}}
      </template>
      <template #cell(color)="data">
          <span class="products_color_inside d-inline-block"
                :style="{'background-color': data.item.info.color.color }"></span>
      </template>
      <template #cell(unitPrice)="data">
        <span class="currency">{{ data.item.sale_price }} {{ $t('egp')}}</span>
      </template>
      <template #cell(subtotal)="data">
        <span class="currency">{{ data.item.total_price }} {{ $t('egp')}}</span>
      </template>
    </b-table>
<!--    <b-row class="p-3">-->
<!--      <b-col lg="12" class="text-initial"><p class="text-warning px-2">{{ $t('order.cart') }}</p></b-col>-->
<!--      <b-col lg="12" v-for="(product, key) in order.products" :key="key">-->
<!--        <b-row class="p-3 px-3">-->
<!--          <b-col lg="5" class="d-flex justify-content-start">-->
<!--            <router-link :to="{name: 'productDetails', params: {id: product.info.product_id}}">-->
<!--              <img :src="product.info.image" class="table-img mx-2">-->
<!--              {{ product.info.product.name.length  > 40 ? product.info.product.name.substr(0,40) + '...': product.info.product.name }}-->
<!--            </router-link>-->
<!--          </b-col>-->
<!--          <b-col lg="7"><b-row>-->
<!--            <b-col lg="3" class="text-initial"> {{ product.info.size.name }}</b-col>-->
<!--            <b-col lg="3" class="text-initial">{{ product.uintprice + $t('egp') }}</b-col>-->
<!--            <b-col lg="3" class="text-initial">× {{ product.quantity }}</b-col>-->
<!--            <b-col lg="3" class="text-initial">{{ product.total_price + $t('egp') }}</b-col>-->
<!--          </b-row></b-col>-->
<!--        </b-row>-->
<!--      </b-col>-->
<!--    </b-row>-->
  </div>
</template>

<script>
import { core } from '@/config/pluginInit'
import ProfileServices from '@/modules/profile/services/profile.services'

export default {
  data () {
    return {
      order: {},
      summary: {
        items: 0,
        tax: 0,
        subtotal: 0,
        deliveryFees: 0,
        total: 0
      },
      cart: {},
      tableHeader: [
        { key: 'productName', label: this.$t('cart.name'), class: 'px-3 text-initial' },
        { key: 'size', label: this.$t('cart.size'), class: 'px-3 text-center' },
        { key: 'color', label: this.$t('productDetails.color-only'), class: 'px-3 text-center' },
        { key: 'unitPrice', label: this.$t('cart.unitPrice'), class: 'px-3 text-center' },
        { key: 'quantity', label: this.$t('cart.quantity'), class: 'px-3 text-center' },
        { key: 'subtotal', label: this.$t('cart.subtotal'), class: 'px-3 text-center' }
      ],
      orderStatusTypes: [
        { label: this.$t('order.placed'), status: 'order placed', class: 'grey-tag' },
        { label: this.$t('order.shipment'), status: 'in shipment', class: 'blue-tag' },
        { label: this.$t('order.canceled'), status: 'canceled', class: 'red-tag' },
        { label: this.$t('order.delivered'), status: 'delivered', class: 'green-tag' },
        { label: this.$t('order.pending'), status: 'pending', class: 'blue-tag' },
        { label: this.$t('order.shipped'), status: 'shipped', class: 'yellow-tag' }
      ]
    }
  },
  methods: {
    getOrderDetails () {
      ProfileServices.getOrderDetails(this.$route.params.id).then(res => {
        this.order = res.data.data
        this.cart = this.order.product
      })
    }
  },
  created () {
    console.log(this.$route.params.id)
    this.getOrderDetails()
  },
  mounted () {
    core.index()
  }
}
</script>
